<template>
  <div id="reset-page" class="pt-9">
    <app-box width="small">
      <template #legend>Reset your password</template>
      <transition name="fade">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <app-form-alert v-if="form.error || form.success" :type="form.success ? 'success' : 'error'" v-html="form.success ? form.success : form.error" />
      </transition>
      <app-form v-if="!form.success" @submit.prevent="submitForm">
        <app-form-field>
          <app-label id="email">
            Email address
          </app-label>
          <app-input id="email" v-model.trim="form.fields.email" autocomplete="email" />
        </app-form-field>
        <app-button class="mt-3 transition-colors" :disabled="loading || requiredFieldIsBlank">
          Send reset link
        </app-button>
      </app-form>
      <hr class="mt-8 mb-2 border-gray-300">
      <p class="text-xs">
        <router-link to="/login" class="text-blue-600 underline hover:no-underline">
          &larr; Go back to login.
        </router-link>
      </p>
    </app-box>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Reset",
  data: () => ({
    form: {
      fields: {
        email: "",
      },
      error: "",
      success: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    requiredFieldIsBlank() {
      const { email } = this.form.fields;
      return email === "" || !email.includes("@");
    },
  },
  methods: {
    async submitForm() {
      if (this.invalidForm) return;
      const { email } = this.form.fields;
      const url = "api/email/reset";
      try {
        await U.api({ url, method: "post", body: { email } });
        this.form.success = `Email sent.<br><span class="font-normal">Please check your email, we have sent a password reset link to you.</span>`;
      } catch (e) {
        this.form.error = `Account not found.<br><span class="font-normal">We could not find an account with that email address, please try again.</span>`;
      }
    },
  },
};
</script>
